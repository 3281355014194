 function PagesAdmin() {
	this.title = "";
	this.slug = "";
	this.manualSlug = false;
    this.content = "";
    this.published = false;
    this.id = false;
    this.id = $("#pages_id").attr("pageid");
	if (this.id != "-1") {
		this.manualSlug = true;
	}
}

PagesAdmin.prototype = {
	constructor: PagesAdmin,
	setTitle: function(title) {
		this.title = title;
		this.generateSlug();
	},
	generateSlug: function() {
		if (this.manualSlug) {
			return;
		}
		this.slug = getSlug(this.title, {
			lang: "de"
		});
		this.displaySlug();
	},
	setSlug: function(slug) {
		this.slug = slug;
		if (slug.length > 0) {
			this.manualSlug = true;
		} else {
			this.manualSlug = false;
		}
	},
    setContent: function(content) {
        this.content = content;
    },
    setPublished: function(published) {
        this.published = published;
    },
	displaySlug: function() {
		$("#pages_slug").val(this.slug);
	},
    filterParent: function(object) {        
        if (this.id === false) {
            return true;
        }
        if (this.id == object.id) {
            // Cannot be you own parent
            return false;
        }
        if (object.properties.parents.includes(this.id)) {
            return false;
        }
        return true;
    },
    labelParent: function(object) {
        let title = object.properties.title;
        let path = object.properties.path;
        let parents = object.properties.parents;
        let id = object.id;
        let indent = Array(parents.length).join("&nbsp;&nbsp;&nbsp;");
        return `${indent}${title} (${path} - #${id})`;
    },
    sortParents: function(objects) {
        return objects.sort((a, b) => {
            return a.properties.path.localeCompare(b.properties.path);
        });
    },
    save: function(data, callback) {
        $.ajax({
            url: "admin/pages/api/save",
            method: "POST",
            data: {
                data: data
            },
            success: function(response) {
                callback(response);
            },
            error: function() {
                callback(false);
            }
        });
    },
    trash: function(onSuccess, onError) {
        $.ajax({
            url: "admin/pages/api/trash",
            method: "POST",
            data: {
                "id": this.id
            },
            success: function(response) {
                onSuccess();
            },
            error: function() {
                onError();
            }
        });
    },
    publish: function(onSuccess, onError) {
        $.ajax({
            url: "admin/pages/api/publish",
            method: "POST",
            data: {
                "id": this.id
            },
            success: function(response) {
                onSuccess();
            },
            error: function() {
                onError();
            }
        });
    },
    draft: function(onSuccess, onError) {
        $.ajax({
            url: "admin/pages/api/draft",
            method: "POST",
            data: {
                "id": this.id
            },
            success: function(response) {
                onSuccess();
            },
            error: function() {
                onError();
            }
        });
    },
    delete: function(onSuccess, onError) {
        $.ajax({
            url: "admin/pages/api/delete",
            method: "POST",
            data: {
                "id": this.id
            },
            success: function(response) {
                onSuccess();
            },
            error: function() {
                onError();
            }
        });
    },
    getData: function() {
        var data = {};
        data.id = $("#pages_id").attr("pageid");

        data.title = $("#pages_title").val();
        data.slug  = $("#pages_slug").val();
        data.content  = JSON.stringify(PostEditor.getInstance("#pageit-editor").export());
        
        if ($("#admin_pages_parent_enable").is(":checked")) {
            data.parent = $("#admin_pages_parent").val();
        } else {
            data.parent = -1;
        }
        
        
        data.publish = $("input[name=admin_pages_publish]:checked").val();
        data.publish_date = $("#admin_pages_publish_time_date").val();
        data.publish_time = $("#admin_pages_publish_time_time").val();
        data.comments = $("#pages_comments").prop("checked") ? "opened" : "closed";

        data.posts = $("#pages_posts").prop("checked");
        data.posts_start_limit = $("#pages_posts_startcb").prop("checked");
        data.posts_start_type = $("input[name=pages_posts_start_type]:checked").val();
        data.posts_start_date = $("#pages_posts_start_date").val();
        data.posts_start_duration = $("#pages_posts_start_duration").attr("seconds");

        data.posts_end_limit = $("#pages_posts_endcb").prop("checked");
        data.posts_end_type = $("input[name=pages_posts_end_type]:checked").val();
        data.posts_end_date = $("#pages_posts_end_date").val();
        data.posts_end_duration = $("#pages_posts_end_duration").attr("seconds");

        data.posts_default_deactivation_enabled = $("#pages_posts_deactivationcb").prop("checked");
        data.posts_default_deactivation_time = $("#pages_posts_deactivation_time").attr("seconds");
        data.posts_ignore_deactivation = $("#pages_posts_ignore_deactivation").prop("checked");

        data.posts_sort = $("input[name=admin_pages_post_sort]:checked").val();
		
		
        data.posts_columns = $("input[name=admin_pages_posts_columns]:checked").val();
        data.posts_layout = $("input[name=admin_pages_posts_layout]").val();

        data.posts_categories_type = $("input[name=admin_pages_posts_categories]:checked").val();
        data.posts_categories = $("#page_post_categories").val();
        if (!data.posts_categories) {
            data.posts_categories = [];
        }
        
        data.categories = $("#page_categories").val();
        if (!data.categories) {
            data.categories = [];
        }
        return data;
    }
}


$(document).ready(function() {    
    if (typeof M === "undefined") {
        return;
    }
    var pickers = $(".admin_pages_datepicker");
    M.Datepicker.init(pickers, {
        format: 'dd.mm.yyyy'
    });

    $(".admin_pages_timepicker")
        .timepicker({
            format: "HH:mm",
            twelveHour: false
        });
});

var pageAdmin = new PagesAdmin();

// Add
$("#pages_title").on("input", function() {
    pageAdmin.setTitle($(this).val());
});
$("#pages_slug").on("input", function() {
    pageAdmin.setSlug($(this).val());
});


// Trash 
$("body").on("click", ".admin_pages_action_trash", function() {
    var id = $(this).attr("page");
    var that = this;
    pageAdmin.id = id;
    pageAdmin.trash(function() {
        // On Success
        var notify = new Notification();
        notify.success("Seite in den Papierkorb verschoben");
        // Delete table Row
        $(that).closest("tr").fadeOut("fast", function() {
            $(that).closest("tr").remove();
        });
    }, function() {
        // On Error
        var notify = new Notification();
        notify.error("Seite konnte nicht in den Papierkorb verschoben werden");
    });
});

// Publish
$("body").on("click", ".admin_pages_action_publish", function() {
    var id = $(this).attr("page");
    var location = $(this).attr("location");
    var that = this;
    pageAdmin.id = id;
    pageAdmin.publish(function() {
        // On Success
        var notify = new Notification();
        notify.success("Seite wurde veröffentlicht");
        if (location == "start") {
            $("#admin_pages_action_publish_"+id).hide();
            $("#admin_pages_text_draft_"+id).hide();
            $("#admin_pages_action_draft_"+id).show();
        } else if (location == "trash") {
            // Delete table Row
            $(that).closest("tr").fadeOut("fast", function() {
                $(that).closest("tr").remove();
            });
        }        
    }, function() {
        // On Error
        var notify = new Notification();
        notify.error("Seite konnte nicht veröffentlicht werden");
    });
});

// Draft
$("body").on("click", ".admin_pages_action_draft", function() {
    var id = $(this).attr("page");
    var location = $(this).attr("location");
    var that = this;
    pageAdmin.id = id;
    pageAdmin.draft(function() {
        // On Success
        var notify = new Notification();
        notify.success("Seite wurde als Entwurf markiert");
        if (location == "start") {
            $("#admin_pages_action_publish_"+id).show();
            $("#admin_pages_text_draft_"+id).show();
            $("#admin_pages_action_draft_"+id).hide();
        } else if (location == "trash") {
            // Delete table Row
            $(that).closest("tr").fadeOut("fast", function() {
                $(that).closest("tr").remove();
            });
        }  
    }, function() {
        // On Error
        var notify = new Notification();
        notify.error("Seite konnte nicht als Entwurf markiert werden");
    });
});

// Delete Page (Forever!)
$("body").on("click", ".admin_pages_action_delete", function() {
    var id = $(this).attr("page");
    var location = $(this).attr("location");
    var that = this;

    var dlg = new Dialog();
    dlg.confirm(
        "Wirklich löschen?",
        `Wollen Sie diese Seite und alle damit verbundenen Daten unwiderruflich löschen?<br />
        Beachten Sie, dass hierbei möglicherweise das Aufrufen von Unterseiten unmöglich wird, bis Sie
        diese manuell einer neuen Elternseite zuweisen!`,
        "Ja",
        "Nein",
        function(res) {
            if (res) {
                pageAdmin.id = id;
                pageAdmin.delete(function() {
                    // On Success
                    var notify = new Notification();
                    notify.success("Seite wurde gelöscht");
                    // Delete table Row
                    $(that).closest("tr").fadeOut("fast", function() {
                        $(that).closest("tr").remove();
                    });
                }, function() {
                    // On Error
                    var notify = new Notification();
                    notify.error("Seite konnte nicht gelöscht werden");
                });
            }
        }
    )
    
});

// Bulk Select
$("body").on("change", "#pages_select_all", function() {
    var checked = $(this).prop("checked");
    $(".pages_select").prop("checked", checked);
});

// Bulk Actions
// Trash delete
$("body").on("click", "#page_trash_delete", function() {
    var dlg = new Dialog();
    dlg.confirm(
        "Wirklich löschen?",
        `Wollen Sie alle markierten Seiten und alle damit verbundenen Daten unwiderruflich löschen?<br />
        Beachten Sie, dass hierbei möglicherweise das Aufrufen von Unterseiten unmöglich wird, bis Sie
        diese manuell einer neuen Elternseite zuweisen!`,
        "Ja",
        "Nein",
        function(res) {
            if (res) {
                $(".pages_select:checked").each(function() {
                    var that = this;
                    pageAdmin.id = $(this).attr("pageid");
                    pageAdmin.delete(function() {
                        // On Success
                        var notify = new Notification();
                        notify.success("Seite wurde gelöscht");
                        // Delete table Row
                        $(that).closest("tr").fadeOut("fast", function() {
                            $(that).closest("tr").remove();
                        });
                    }, function() {
                        // On Error
                        var notify = new Notification();
                        notify.error("Seite konnte nicht gelöscht werden");
                    });
                });                
            }
        }
    )
});
// Trash Restore
$("body").on("click", "#page_trash_restore", function() {    
    $(".pages_select:checked").each(function() {
        var that = this;
        pageAdmin.id = $(this).attr("pageid");
        pageAdmin.publish(function() {
            // On Success
            var notify = new Notification();
            notify.success("Seite wurde wiederhergestellt");
            // Delete table Row
            $(that).closest("tr").fadeOut("fast", function() {
                $(that).closest("tr").remove();
            });
        }, function() {
            // On Error
            var notify = new Notification();
            notify.error("Seite konnte nicht wiederhergestellt werden");
        });
    });
});
// Page to Trash
$("body").on("click", "#page_move_to_trash", function() {    
    $(".pages_select:checked").each(function() {
        var that = this;
        pageAdmin.id = $(this).attr("pageid");
        pageAdmin.trash(function() {
            // On Success
            var notify = new Notification();
            notify.success("Seite wurde in den Papierkorb verschoben");
            // Delete table Row
            $(that).closest("tr").fadeOut("fast", function() {
                $(that).closest("tr").remove();
            });
        }, function() {
            // On Error
            var notify = new Notification();
            notify.error("Seite konnte nicht in den Papierkorb verschoben werden");
        });
    });
});


// Parent Pages
$("body").on("change", "#admin_pages_parent_enable", function() {
    var checked = $(this).prop("checked");
    if (checked) {
        $("#admin_pages_parent_expand").slideDown("fast");
    } else {
        $("#admin_pages_parent_expand").slideUp("fast");
        $(this).attr("parent", "-1");
        $(".admin_pages_parent_item").removeClass("admin_pages_parent_item_selected");
        $("#base_url").text($("#base_url").attr("base_url"));
    }
});

// Add/Posts
$("body").on("change", "#pages_posts", function() {
    var checked = $(this).prop("checked");
    if (checked) {
        $("#admin_pages_posts_settings").slideDown("fast");
    } else {
        $("#admin_pages_posts_settings").slideUp("fast");        
    }
});

// Start Limit change
$("body").on("change", "#pages_posts_startcb", function() {
    var checked = $(this).prop("checked");
    if (checked) {
        $("#pages_posts_start_settings").slideDown("fast");
    } else {
        $("#pages_posts_start_settings").slideUp("fast");
    }
});

// End Limit change
$("body").on("change", "#pages_posts_endcb", function() {
    var checked = $(this).prop("checked");
    if (checked) {
        $("#pages_posts_end_settings").slideDown("fast");
    } else {
        $("#pages_posts_end_settings").slideUp("fast");
    }
});

// Start Limit Type Change
$("body").on("change", "input[type=radio][name=pages_posts_start_type]", function() {
    if ($(this).val() == "date") {
        $("#pages_posts_start_date_settings").slideDown("fast");
        $("#pages_posts_start_duration_settings").slideUp("fast");
    } else {
        $("#pages_posts_start_date_settings").slideUp("fast");
        $("#pages_posts_start_duration_settings").slideDown("fast");   
    }
});

// End Limit Type Change
$("body").on("change", "input[type=radio][name=pages_posts_end_type]", function() {
    if ($(this).val() == "date") {
        $("#pages_posts_end_date_settings").slideDown("fast");
        $("#pages_posts_end_duration_settings").slideUp("fast");
    } else {
        $("#pages_posts_end_date_settings").slideUp("fast");
        $("#pages_posts_end_duration_settings").slideDown("fast");   
    }
});

// Deactivation change
$("body").on("change", "#pages_posts_deactivationcb", function() {
    var checked = $(this).prop("checked");
    if (checked) {
        $("#pages_posts_deactivation_settings").slideDown("fast");
    } else {
        $("#pages_posts_deactivation_settings").slideUp("fast");
    }
});

// Post-Categories
$("body").on("change", "input[type=radio][name=admin_pages_posts_categories]", function() {
    if ($(this).val() == "select") {
        $("#admin_pages_posts_settings_categories").slideDown("fast");
    } else {
        $("#admin_pages_posts_settings_categories").slideUp("fast");        
    }
});

// Publish-Times
$("body").on("change", "input[type=radio][name=admin_pages_publish]", function() {
    if ($(this).val() == "time") {
        $("#admin_pages_publish_time_settings").slideDown("fast");
    } else {
        $("#admin_pages_publish_time_settings").slideUp("fast");        
    }
});

$('.admin_input_duration').timeDurationPicker({
    onselect: function(element, seconds, humanDuration) {
        element.val(humanDuration);
        element.attr("seconds", seconds);
    },
    onInit: function(picker, element) {
        var seconds = element.attr("seconds");
        picker.setDuration(seconds);
        element.val(picker.getHumanDuration());
    }
});

// INIT PRESETS
$(function() {
    // PARENT
    let parent = $("#admin_pages_parent_expand").find(".adminpobjectselect").data("value");
    
    if (parent != "-1") {
        $("#admin_pages_parent_enable").trigger("click");
    }    

    $("body").on("change", "#admin_pages_parent", function() {
        let page = $(this).val();
        if (page == -1) {
            $("#base_url").text($("#base_url").attr("base_url"));
            return;
        }
        $.ajax({
            url: "api/admin/pobject_info",
            method: "post",
            data: {
                "class": "\\P\\Posts\\Page",
                "id": page
            },
            success: function(resp) {                
                let path = resp.object.properties.path + "/";
                $("#base_url").text($("#base_url").attr("base_url") + path);
            },
            error: function() {
                new Notification().error("Seiteninformationen konnten nicht geladen werden");
            }
        });
    });

    // PUBLISH
    var preset = $("#admin_pages_publish_preset").attr("preset");
    $("#admin_pages_publish_preset").find("input").prop("checked", false);
    $("#admin_pages_publish_"+preset).prop("checked", true);

    // COMMENTS
    var comments = $("#pages_comments").attr("preset");
    if (comments) {
        $("#pages_comments").trigger("click");
    }

    // POSTS
    var posts = $("#pages_posts").attr("preset");
    if (posts) {
        $("#pages_posts").trigger("click");
    }

    var time_limit_start = $("#pages_posts_startcb").attr("preset");
    if (time_limit_start) {
        $("#pages_posts_startcb").trigger("click");
    }

    var time_limit_end = $("#pages_posts_endcb").attr("preset");
    if (time_limit_end) {
        $("#pages_posts_endcb").trigger("click");
    }

    let time_limit_deactivation = $("#pages_posts_deactivationcb").attr("preset");
    if (time_limit_deactivation) {
        $("#pages_posts_deactivationcb").trigger("click");
    }

    var start_type = $("#pages_posts_start_settings").attr("preset");
    $("#pages_posts_start_"+start_type+"_cb").trigger("click");

    var end_type = $("#pages_posts_end_settings").attr("preset");
    $("#pages_posts_end_"+end_type+"_cb").trigger("click");

    let ignore_deactivation = $("#pages_posts_ignore_deactivation").attr("preset");
    if (ignore_deactivation) {
        $("#pages_posts_ignore_deactivation").trigger("click");
    }

    // Post Sort
    var sort = $("#posts_sort").attr("preset");
    $("#admin_pages_post_sort_"+sort).trigger("click");

    // Post Categories Type
    var posts_categories_type = $("#posts_categories_type").attr("preset");
    $("#admin_pages_posts_cat_"+posts_categories_type).trigger("click");
	
	// Post columns
	var columns = $("#admin_pages_posts_columns_preset").attr("preset");
	$("input[name=admin_pages_posts_columns][value=" + columns + "]").trigger("click");

    // END POSTS
});



// Create New Page
$("#pages_save").click(function() {
    var data = pageAdmin.getData();

    if (data.title == "" || data.slug == "") {
        var notify = new Notification();
        notify.error("Titel und Pfad dürfen nicht leer sein!");
        return;
    }

    var loading = new Dialog();
    loading.loading(
        "Seite wird gespeichert",
        "Bitte warten Sie, während die Seite gespeichert wird..."
    );

    pageAdmin.save(data, function(result) {
        loading.close();
        var notify = new Notification();
        if (!result) {
            if (data.id === "-1") {
                notify.error("Seite konnte nicht erstellt werden.");
            } else {
                notify.error("Seite konnte nicht bearbeitet werden.");
            }
        } else {
            PostEditor.getInstance("#pageit-editor").saved();
            if (data.id === "-1") {
                notify.success("Seite wurde erstellt. Sie werden weitergeleitet.");
                $("#pages_save").hide();
                setTimeout(function() {
                    location.href = "admin/pages/edit/" + result.id;
                }, 2000);
            } else {
                notify.success("Seite wurde gespeichert.");
            }
        }
    });
});

// Trash Page
$("#pages_trash").click(function() {
    var data = pageAdmin.getData();
    if (data.id == "-1") {
        return false;
    }
    
    pageAdmin.trash(function() {
        // On Success
        var notify = new Notification();
        notify.success("Seite wurde in den Papierkorb verschoben");
        // Delete table Row
        location.href = "admin/pages/trash";
    }, function() {
        // On Error
        var notify = new Notification();
        notify.error("Seite konnte nicht in den Papierkorb verschoben werden");
    });
});